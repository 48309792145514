:root, body {

    font-size: 1rem;

}

.is-invalid {
    box-shadow: none !important;
    border: 1.5px solid red !important;
    border-radius: 8px;
}

.main-panel {
    @media (min-width: 768px) {
        width: calc(100% - 255px);
    }
}

.navbar.default-layout .navbar-brand-wrapper {
    @media (max-width: 991px) {
        width: 255px;
    }
}


.page-body-wrapper:not(.auth-page){
    @media(max-width: 767px) {
        padding-top: 0;
    }
}

.sidebar-offcanvas {

    @media (max-width: 991px) {
        position: relative;
        top: auto;
        bottom: auto;
        right: auto;
        min-width: 255px;

        .active {
            right: auto;
        }
    }

    @media (max-width: 767px) {
        position: fixed;
        max-height: 100%;
        width: 100%;
        top: 0;
        bottom: 0;
        right: auto;
        left: -100%;
        padding-top: 0;
        overflow: auto;
        background: linear-gradient(90deg,#5933C6 1.49%,#7E60D5 99.11%);
        -webkit-transition: all 0.25s ease-out;
        -o-transition: all 0.25s ease-out;
        transition: all 0.25s ease-out;
    }

    .active {
        left: 0;

    }
}

.sidebar .nav .nav-item.nav-profile .nav-link {
    @media (max-width: 767px) {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }
}

.sidebar-offcanvas-active {
    right: auto;
    left: 0 !important;
}

.sidebar .nav .nav-item {
    @media (max-width: 767px) {
        &:hover {
            background: transparent;
        }
        .nav-link {
            padding-left: 0;
            padding-right: 0;
            color: white !important;


            &:hover {
                background-color: transparent !important;

                .menu-title {
                    font-weight: bold;
                    text-shadow: 0 0 1px black;
                }
            }

            .menu-icon {
                margin-right: 0.75rem;

                color: white !important;
                font-size: 18px;
            }

            .menu-title {
                font-size: 14px;

                -webkit-transition: ease-in-out 0.1s;
                -moz-transition: ease-in-out 0.1s;
                -ms-transition: ease-in-out 0.1s;
                -o-transition: ease-in-out 0.1s;
                transition: ease-in-out 0.1s;
            }
        }
    }
}

.sidebar .nav .active {
    @media (max-width: 767px) {
        .nav-link {
            .menu-title {
                font-weight: bold;
                text-shadow: 0 0 1px black;
            }
        }
    }
}

.content-wrapper {
    padding: 25px;
}

.navbar-brand-wrapper {

    background: #5933C6 !important;

}

.brand-logo-mini img {
    @media (max-width: 767px) {
        height: 28px;
    }
}

.navbar.default-layout {
    background: linear-gradient(90deg, $primary 0%, $primary-hover 100%);
}

.navbar.default-layout .navbar-brand-wrapper .navbar-brand img {
    width: auto;
}

.navbar-menu-wrapper {
    @media (min-width: 768px) {
        justify-content: space-between;
        width: calc(100% - 255px) !important;

        padding-left: 25px !important;
        padding-right: 25px !important;
    }
    .navbar-nav.header-links {
        padding-left: 0 !important;
    }
}

.navbar-nav-right {
    @media (min-width: 768px) {
        margin-left: auto;
    }
}

.nav-item {

    &--hover {
        -webkit-transition: ease-in-out 0.2s;
        -moz-transition: ease-in-out 0.2s;
        -ms-transition: ease-in-out 0.2s;
        -o-transition: ease-in-out 0.2s;
        transition: ease-in-out 0.2s;

        &:hover {
            background-color: $primary-hover;
        }
    }
}

.nav .nav-item.dropdown .navbar-dropdown, .navbar-nav .nav-item.dropdown .navbar-dropdown {
    max-height: 300px;

    padding-bottom: 0;

    overflow: auto;

    @media (min-width: 768px) {
        right: -26px;
    }

    @media(max-width: 767px) {
        right: -16px;
    }

    @media(max-width: 575px) {
        max-width: 250px;
    }
}

.nav .nav-item.dropdown .navbar-dropdown .dropdown-item, .navbar-nav .nav-item.dropdown .navbar-dropdown .dropdown-item {
    padding: 7px 15px;

    &:active {
        color: #212529;
    }
}
.nav .nav-item.dropdown .navbar-dropdown .dropdown-item .preview-icon, .navbar-nav .nav-item.dropdown .navbar-dropdown .dropdown-item .preview-icon, .preview-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
    width: 35px;

    border-radius: 100%;

    color: white !important;
    font-weight: bold;
}

.alert {
    margin-bottom: 25px;
    border-radius: 2px;

    font-size: 14px;

    button {
        font-size: 20px;

        span {
            font-size: 18px;
        }
    }
}

.card-title {
    margin-bottom: 0;
    margin-right: 0.5rem;

    color: $primary;
    font-size: 18px;
    font-weight: bold;
    text-transform: none;

    @media (max-width: 768px) {
        font-size: 16px;
    }
}

.card-top {
    padding-bottom: 20px;
}

.btn {
    border: none;

    &:focus {
        box-shadow: none !important;
    }
}

.btn-secondary {
    background: $secondary;
    color: rgb(255, 255, 255);

    &:hover {
        background: $secondary-hover;
        color: rgb(255, 255, 255);
    }

    &:focus, &:active {
        background: $secondary-hover !important;
        color: rgb(255, 255, 255) !important;
    }

    .disabled, &:disabled {
        background: $secondary-hover;
        color: rgb(255, 255, 255);
    }
}

.btn-primary {
    background: $primary;
    color: rgb(255, 255, 255);

    &:hover {
        background: $primary-hover;
    }

    &:focus, &:active {
        background: $primary-hover !important;
    }

    .disabled, &:disabled {
        background: $primary-hover !important;
    }
}

.btn-primary.disabled, .btn-primary:disabled {
    background: $primary-hover !important;
}

.btn-light {
    background-color: $lightGrey;

    &:hover {
        background-color: $grey;
    }
}

.table {

    thead th {
        font-weight: bold;
    }

    th, td {
        border: none;
        border-top: 2px solid $lightGrey;

        line-height: 19.5px;
    }

    td:not(:last-child) {
        border-right: 1px solid $lightGrey;
    }
}

.form-control {
    height: auto;
    min-height: 36px;

    border: 1.5px solid $lightGrey;
    border-radius: 5px;

    &--filter-table {
        display: inline-block;
        width: auto;

        margin-right: 20px;

        vertical-align: top;

        &:last-child {
            margin-right: 0;
        }

        &--search {
            min-width: 30%;
        }
    }

    &--search {
        z-index: 1;
        position: relative;

        padding-left: 30px;

        background: url('../images/search.svg') no-repeat 8px;
    }


    &:focus {
        border-color: $primary;
    }

    &--grey-focus-effect {

        &:focus {
            border-color: #aaa;
        }
    }
}

.input-group-append {

    border: 1.5px solid $lightGrey;
    border-left: none;

}

.input-group-preped {

    border: 1.5px solid $lightGrey;

}

.custom-checkbox {


    &--th {
        margin-bottom: -6px;
    }
}

.custom-control-input {

    &:checked ~ .custom-control-label::before {
        border: solid 1.5px $primary;

        background-color: $primary-hover !important;
    }

    &:focus  ~ .custom-control-label::before {
        border: solid 1.5px $primary !important;

        box-shadow: none !important;
    }

    &:active  ~ .custom-control-label::before {

        border: solid 1.5px $primary !important;

        background-color: white !important;
    }
}

label, span, p {

    font-size: 13px;

}

.applyBtn.btn-success {
    background: $secondary;
}

.daterangepicker td.in-range {
    background-color: $primary-hover !important;
    color: $white !important;
}

.daterangepicker td.off, .daterangepicker td.off.end-date, .daterangepicker td.off.in-range, .daterangepicker td.off.start-date {
    color: $lightGrey !important;
}

.daterangepicker td.active, .daterangepicker td.active:hover {
    background-color: $primary !important;
}



.reportrange-text {
    height: auto !important;
    padding: 0.56rem 0.75rem !important;
    border: none !important;
}

tr td.td-fit {
    width:1%;
    white-space:nowrap;
}

.form-control.no-padding {
    padding: 0;
}

.applyBtn .btn-success {
    background: $secondary;
}

.custom-control-label {
    padding-top: 4px;

    cursor: pointer;

    &:before {
        left: -20px;

        border: solid 1.5px $primary;

        background-color: white;

        &:focus, &:active, &:checked, &::selection {
            box-shadow: none;
            outline: none;
        }
    }

    &:after {
        left: -20px;
    }

    &--mt {
        margin-top: 5px;
    }
}

.footer a {

    color: $secondary;

    &:hover {

        color: $secondary-hover;

    }



}

.footer-text {

    color: #e6e6e6 !important;

}



.login {

    height: 100vh;

    background:
        linear-gradient(to bottom,
        rgba(255, 255, 255, 0.1),
        rgba(255, 255, 255, 0.9)
        ), url("../images/bg.jpg");

    background-size: cover;

    .navbar a {
        color: rgb(255, 255, 255);
    }

    .navbar .navbar-brand-wrapper {
        background: transparent !important;
    }

    .card {
        border-radius: 20px;
    }

}

a.text-primary {
    font-weight: bold;
}

a.text-primary:hover, a.text-primary:focus  {
    color: $primary !important;
}


.border-primary {
    border-color: $primary !important;
}

.bg-success {
    background-color: #ccf5e1 !important;
}

.bg-success-og {
    background-color: #00ce68 !important;
}

.bg-danger {
    background-color: #fadcdc !important;
}

.bg-danger-og {
    background-color: #e65251 !important;
}
